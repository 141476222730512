import { Box, Button, Divider, Grid, Switch, Typography } from "@mui/material";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddCustomModule from "./AddCustomModule/AddCustomModule";
import folderTree from "../../folderTreeTrans.png";
import "./SettingsPage.css";
import RootFolderSelection from "./RootFolderSelection/RootFolderSelection";
import { useEffect, useState } from "react";
import axios from "axios";
import * as ApiCall from "./../API/ApiCalling";
import MySortableTree from "../Others/SortableTree/MySortableTree";
import { useSnapshot } from "valtio";
import { widState } from "../../Store/workdriveState";
import useCrmVariable from "../../CustomHook/useCrmVariable";
import CronLIst from "./CronList/CronLIst";
import ModuleFolderStructure from "../Others/ModuleFolderStructure/ModuleFolderStructure";
import ModuleDefaultSetting from "./ModuleDefaultSetting/ModuleDefaultSetting";

export default function SettingsPage() {
  const snap = useSnapshot(widState);
  const [open, setOpen] = useState(false);
  const [selectedModule, setSelectedModule] = useState("Accounts");
  const [customSettingData, setCustomSettingData] = useState();

  const { orgid, connname, apikey, zuid, datacenterurl, zapikey } =
    useCrmVariable({});
  const settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL;

  const sequelizeModule = (moduleArray) => {
    const defaultSequence = [
      "Leads",
      "Contacts",
      "Accounts",
      "Deals",
      "Campaigns",
    ];
    let result = [];
    defaultSequence.forEach((seqModule) => {
      let deleteIndex = 0;
      const temObject = moduleArray.find(({ module_name }, index) => {
        if (module_name === seqModule) {
          deleteIndex = index;
          return true;
        }
      });
      moduleArray.splice(deleteIndex, 1);
      result.push(temObject);
    });
    return [...result, ...moduleArray];
  };

  useEffect(() => {
    async function getSettingData() {
      try {
        const settingDetails = await ApiCall.getSettingData(
          "",
          "systemDefined",
          connname,
          orgid,
          apikey,
          datacenterurl,
          settingUrl
        );

        if (settingDetails?.error) {
          return;
        }
        const result = {};
        const stringResult = {};
        sequelizeModule(settingDetails.data)
          // .filter((module) => module?.show_in_related_list == true)
          .forEach((module) => {
            // check new setting or not
            console.log({
              newSchema: { ...JSON.parse(module?.setting_schema) },
            });
            const newFolderStructure =
              JSON.parse(module?.setting_schema)?.newFolderStructure || false;

            let newSchema = { ...JSON.parse(module?.setting_schema) };
            let shouldConvert = newSchema?.treeData[0]?.information
              ? false
              : true;
            if (shouldConvert) {
              console.log({ newSchema });
              let newTreeData = [
                {
                  ...newSchema?.treeData[0],
                  information: {
                    shareUrl: {},
                    saveFolder: {
                      value: {
                        type: "text",
                        fieldApiName: newSchema?.fieldApiName?.api_name,
                        lookupApiName: "",
                        fieldDisplayLabel:
                          newSchema?.fieldApiName?.display_name,
                        lookupDisplayLabel: newSchema?.moduleApiName,
                      },
                      isChecked: true,
                    },
                    copyLeadFolder: {},
                    moveFolderIfExist: {},
                    usePreviousFolder: {},
                    fullAccessShareUrl: {},
                  },
                },
              ];
              newSchema["treeData"] = newTreeData;
              result[module?.module_name] = {
                ...newSchema,
                settingId: module?.setting_id,
                showInRelatedList: module?.show_in_related_list ? true : false,
              };

              stringResult[module?.module_name] = JSON.stringify({
                ...newSchema,
                settingId: module?.setting_id,
                showInRelatedList: module?.show_in_related_list ? true : false,
              });
            } else {
              result[module?.module_name] = {
                ...JSON.parse(module?.setting_schema),
                settingId: module?.setting_id,
                showInRelatedList: module?.show_in_related_list ? true : false,
              };

              stringResult[module?.module_name] = JSON.stringify({
                ...JSON.parse(module?.setting_schema),
                settingId: module?.setting_id,
                showInRelatedList: module?.show_in_related_list ? true : false,
              });
            }
          });
        setCustomSettingData((prev) => result["Accounts"] ?? {});
        widState.setInitialSetting(result);
        widState.setInitialString(stringResult);
      } catch (error) {}
    }
    if (orgid && connname && apikey) {
      getSettingData();
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [rootFolderID, setRootFolderID] = useState();

  return (
    <Box
      sx={{
        py: 1,
        // px: 4,
        width: "85%",
        mx: "auto",
        mt: 2,
        // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        borderRadius: "10px",
        bgcolor: "#fff",
      }}
    >
      <Box sx={{ px: 4 }}>
        <Typography
          sx={{
            my: 1,
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: "bold",
          }}
        >
          Module Folder Setup
        </Typography>
        <Typography
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "16px",
            fontWeight: 400,
            linetHeight: "24px",
          }}
        >
          Root Folder for Individual Module. All folders for Individual Records
          will be created under this Root Folder.
          <br />
          <b>Folder Structure: </b>It defines how you want to create Folder on
          Record Creation. It can be just only One Folder or nested Folder
          Structure based on Requirement. You need to Set the Folder Structure
          and and Each Folder Names.
          <br />
          <b>
            Note: Use # to use Merge Tag, it will be replaced by Actual Value of
            the Record while Creating Folder.
          </b>
        </Typography>
        <Box sx={{ pb: 3 }} />
      </Box>
      <ModuleDefaultSetting
        orgid={orgid}
        connname={connname}
        apikey={apikey}
        zapikey={zapikey}
        datacenterurl={datacenterurl}
        zuid={zuid}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you want to save Changes?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By pressing agree your setting for extention is going to change.
            Please check twice before pressing agree.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={() => {
              async function upsertData(params) {
                try {
                  const { settingId, showInRelatedList, ...settingSchema } =
                    customSettingData;
                  const reqData = {
                    settingSchema: {
                      ...settingSchema,
                      newFolderStructure: true,
                    },
                    settingId: settingId,
                    showInRelatedList: showInRelatedList,
                    moduleApiName: selectedModule,
                  };

                  console.log({ reqData });

                  const upsertDetails = await ApiCall.upsertData(
                    reqData,
                    connname,
                    orgid,
                    apikey,
                    datacenterurl,
                    settingUrl
                  );
                  if (upsertDetails?.error) {
                    return; // ;
                  }
                } catch (error) {}
              }
              upsertData();
              widState.setStringData();
              handleClose();
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
