const ZOHO = window.ZOHO;

export const flattenObj = (ob, seperator = ".") => {
  // The object which contains the
  // final result
  let result = {};

  // loop through the object "ob"
  for (const i in ob) {
    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if (typeof ob[i] === "object") {
      // HACK to set nested object maximum populated to 1
      // const temp = flattenObj(ob[i], seperator);
      // for (const j in temp) {
      //   // Store temp in result
      //   result[i + seperator + j] = temp[j];
      // }
      result[i] = ob[i]?.name;
    }

    // Else store ob[i] in result directly
    else {
      result[i] = ob[i];
    }
  }
  return result;
};

export const getModuleAndLookupDataAndFields = async (entity, entityId) => {
  let returnData = { fieldNames: {}, recordData: {} };

  /*
  // getRecord of Entity
  const getRecordData = await ZOHO.CRM.API.getRecord({
    Entity: entity,
    RecordID: entityId,
  });

  // code_comment Set recordData as  returnData.recordData = { 
  // Full_Name: "something",
  // Reporting_To.Full_Name: "Reporting_To Name Test1",
  // Account_Name.Full_Name: "Account_Name  Test1"
  // }

  if (getRecordData !== undefined) {
    returnData.recordData = getRecordData.data[0];
  }
*/

  // getFields of entity
  const entityFields = await ZOHO.CRM.META.getFields({ Entity: entity });
  // ;
  /*
    code_comment Set field Data as 
    returnData.fieldNames = {
      Contact: [
        {
          lookupApiName: "",
          lookupDisplayLabel: "",
          fieldApiName: "somehtin",
          fieldDisplayLabel: "somsedkfj"
        },
        ...
      ],
      Account Name: [
        {
          lookupApiName: "Account_Name",
          lookupDisplayLabel: "Account Name",
          fieldApiName: "somehtin",
          fieldDisplayLabel: "somsedkfj"
        },
        ...
      ]
    }
  */

  returnData.fieldNames[entity] = entityFields.fields
    .filter(
      (field) =>
        field.data_type !== "lookup" && field.data_type !== "ownerlookup"
    )
    .map((field) => {
      return {
        lookupApiName: "",
        lookupDisplayLabel: entity,
        fieldApiName: field.api_name,
        fieldDisplayLabel: field.field_label,
        type: field.data_type,
      };
    });

  // ;

  /**
   * Need to use async and await
   */
  return Promise.all(
    entityFields.fields.map(async (lookupField) => {
      if (lookupField.data_type === "lookup") {
        delete returnData?.recordData?.[lookupField.api_name];
        // code_comment getFields of lookup module
        const lookupFields = await ZOHO.CRM.META.getFields({
          Entity: lookupField.lookup.module.api_name,
        });

        // code_comment: Puttong getFields data in below format
        // returnData.fieldNames["Account Name"] = [
        //   {
        //     lookupApiName: "",
        //     lookupDisplayLabel: "",
        //     fieldApiName: field.api_name,
        //     fieldDisplayLabel: field.display_label,
        //   },
        // ];

        if (lookupFields !== undefined) {
          returnData.fieldNames[lookupField.display_label] =
            lookupFields.fields.map((localField) => {
              return {
                lookupApiName: lookupField.api_name,
                lookupDisplayLabel: lookupField.display_label,
                fieldApiName: localField.api_name,
                fieldDisplayLabel: localField.display_label,
              };
            });
        }

        // getRecord of lookup field
        // const lookupData = await ZOHO.CRM.API.getRecord({
        //   Entity: lookupField.lookup.module.api_name,
        //   RecordID: returnData.recordData[lookupField.lookup.api_name],
        // });

        // if (lookupData !== undefined) {
        //   Object.keys(lookupData.data[0]).forEach((key) => {
        //     returnData.recordData[`${lookupField.api_name}.${key}`] =
        //       lookupData.data[0][key];
        //   });
        // }

        // // ;
        /**
         * Return is necessary for Promise.all()
         */
        return lookupFields;
      }
      // else if (lookupField.data_type === "subform") {
      //   delete returnData?.recordData?.[lookupField.api_name];
      //   // getFields of subform module
      //   const lookupFields = await ZOHO.CRM.META.getFields({
      //     Entity: lookupField.subform.module,
      //   });

      //   // if (lookupFields !== undefined) {
      //   //   returnData.fieldNames[lookupField.display_label] = lookupFields.fields;
      //   // }
      //   if (lookupFields !== undefined) {
      //     returnData.fieldNames[lookupField.display_label] =
      //       lookupFields.fields.map((localField) => {
      //         return {
      //           lookupApiName: lookupField.api_name,
      //           lookupDisplayLabel: lookupField.display_label,
      //           fieldApiName: localField.api_name,
      //           fieldDisplayLabel: localField.display_label,
      //         };
      //       });
      //   }

      //   // const lookupData = await ZOHO.CRM.API.getRecord({
      //   //     Entity: fieldMap.lookup.module.api_name,
      //   //     RecordID:
      //   //         returnData.recordData[entity][fieldMap.lookup.api_name],
      //   // });
      //   // if (lookupData !== undefined) {
      //   //     returnData.recordData[fieldMap.display_label] =
      //   //         lookupData.data[0];
      //   // }
      //   /**
      //    * Return is necessary for Promise.all()
      //    */
      //   return lookupFields;
      // }
      else if (lookupField.data_type === "ownerlookup") {
        /**
         * For Owner Lookup Fields
         */

        /**
         * Adding USer Fields
         */

        const lookupFields = await ZOHO.CRM.META.getFields({
          Entity: "users",
        });
        // if (lookupFields.fields !== undefined) {
        //   returnData.fieldNames[lookupField.display_label] = lookupFields.fields;
        // }

        if (lookupFields !== undefined) {
          returnData.fieldNames[lookupField.display_label] =
            lookupFields.fields.map((localField) => {
              return {
                lookupApiName: lookupField.api_name,
                lookupDisplayLabel: lookupField.display_label,
                fieldApiName: localField.api_name,
                fieldDisplayLabel: localField.display_label,
              };
            });
        }

        // /**
        //  * Adding User Data
        //  */
        // const lookupData = await ZOHO.CRM.API.getUser({
        //   ID: returnData?.recordData?.[lookupField.api_name]?.id,
        // });

        // // ;
        // if (lookupData?.users !== undefined) {
        //   // lookupData?.users?.[0].forEach((key) => {
        //   //   returnData.recordData[`${lookupField.api_name}.${key}`] =
        //   //     lookupData?.users?.[0]?.[key];
        //   // });

        //   // Object.keys(lookupData?.users?.[0]).forEach((key)=>{
        //   //   returnData?.recordData?.[`${lookupField.api_name}.${key}`] = lookupData?.users?.[0]?.[key]
        //   // })

        //   Object.keys(lookupData?.users?.[0]).forEach((key) => {
        //     returnData.recordData[`${lookupField.api_name}.${key}`] =
        //       lookupData?.users?.[0]?.[key];
        //   });

        //   // ;
        // }
        // delete returnData?.recordData?.[lookupField.api_name];

        // if (lookupData !== undefined) {
        //   returnData.recordData[fieldMap.display_label] = lookupData.users[0];
        // }
        /**
         * Return is necessary
         */
        return lookupFields;
      }
    })
  ).then((data) => {
    /**
     * After all finished return data
     */

    // returnData["recordData"] = flattenObj(returnData["recordData"]);
    // ;

    return returnData;
  });
};
// Parse value of record data from
export const TranslateMessageTemplate = (string, recordData = {}) => {
  const regexp = /\$.*?}/g;
  const matches = string.matchAll(regexp);
  // ;

  // // ;
  for (const match of matches) {
    // ;
    let getRecordFieldName = match?.[0]?.slice(2, match?.[0]?.length - 1);
    string = string.replace(match?.[0], recordData?.[getRecordFieldName]);
  }
  return string;
};
