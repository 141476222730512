import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import EditNodeForm from "./EditNodeForm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "background.paper",
  borderRadius: "5px",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function EditModalNode({
  currentNodeInfo,
  editModalOpen,
  handleEditModalClose,
  changeNodeAtPath,
  getNodeKey,
  moduleFields,
  leadFields,
  savedData,
  myTreeData,
  moduleName
}) {
  return (
    <Modal
      open={editModalOpen}
      onClose={handleEditModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <EditNodeForm
          moduleName={moduleName}
          title={currentNodeInfo?.node?.title}
          information={{
            saveFolder: currentNodeInfo?.node?.information?.saveFolder || null,
            copyLeadFolder: currentNodeInfo?.node?.information?.copyLeadFolder || null,
            shareUrl: currentNodeInfo?.node?.information?.shareUrl || null,
            fullAccessShareUrl: currentNodeInfo?.node?.information?.fullAccessShareUrl || null,
            usePreviousFolder: currentNodeInfo?.node?.information?.usePreviousFolder || null,
            moveFolderIfExist: currentNodeInfo?.node?.information?.moveFolderIfExist || null,
          }}
          moduleFields={moduleFields}
          leadFields={leadFields}
          myTreeData={myTreeData}
          handleSubmitForm={(data) => {
            const myData = changeNodeAtPath({
              treeData: myTreeData,
              path: currentNodeInfo.path,
              getNodeKey,
              newNode: {
                ...currentNodeInfo.node,
                information: data.information,
                title: data?.title,
              },
            });

            savedData({
              key: "treeData",
              response: myData,
            });
            // console.log({myData});
            handleEditModalClose()
          }}

          // handleSubmitForm={(data) => {
          //   setTreeData((prev) =>
          //     changeNodeAtPath({
          //       treeData: prev,
          //       path: currentNodeInfo.path,
          //       getNodeKey,
          //       newNode: {
          //         ...currentNodeInfo.node,
          //         information: data.information,
          //         title: data.title,
          //       },
          //     })
          //   );
          // }}
        />
      </Box>
    </Modal>
  );
}

export default EditModalNode;
