export const getFilteredOptions = (options, information) => {
  console.log({ information });
  console.log({ options });
  return options?.filter(
    (option) =>
      option?.lookupApiName == "" &&
      (option.type === "text" || option.type === "textarea") &&
      option.fieldApiName !== information?.shareUrl?.value?.fieldApiName &&
      option.fieldApiName !== information?.saveFolder?.value?.fieldApiName &&
      option.fieldApiName !==
        information?.fullAccessShareUrl?.value?.fieldApiName
  );
};

// (field.data_type === "website" || field.data_type === "textarea")

export const getFilteredShareUrlOptions = (options, information) => {
  console.log({ information });
  return options?.filter(
    (option) =>
      option?.lookupApiName == "" &&
      (option.type === "website" || option.type === "textarea") &&
      option.fieldApiName !== information?.shareUrl?.value?.fieldApiName &&
      option.fieldApiName !== information?.saveFolder?.value?.fieldApiName &&
      option.fieldApiName !==
        information?.fullAccessShareUrl?.value?.fieldApiName
  );
};
// let removeFields = [];

export const getInitialFilteredOptions = (
  options,
  sortableTree,
  removeFields,
  index
) => {
  if (sortableTree?.information?.saveFolder?.value?.fieldApiName) {
    removeFields.push(
      sortableTree?.information?.saveFolder?.value?.fieldApiName
    );
  }
  if (sortableTree?.information?.shareUrl?.value?.fieldApiName) {
    removeFields.push(sortableTree?.information?.shareUrl?.value?.fieldApiName);
  }
  if (sortableTree?.information?.fullAccessShareUrl?.value?.fieldApiName) {
    removeFields.push(
      sortableTree?.information?.fullAccessShareUrl?.value?.fieldApiName
    );
  }

  sortableTree?.children?.forEach((element) => {
    removeFields = [
      ...removeFields,
      ...getInitialFilteredOptions(options, element, removeFields, 1),
    ];
  });

  if (index != 0) {
    return removeFields;
  } else {
    let finalReturn = options?.filter(
      (option) =>
        option?.lookupApiName == "" &&
        !removeFields.includes(option?.fieldApiName) &&
        (option.type === "website" ||
          option.type === "textarea" ||
          option.type === "text")
    );
    console.log({ removeFields });
    console.log({ finalReturn });
    return finalReturn;
  }
};

