import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import EditNodeForm from "./EditNodeForm";

const getTitle = (module) => {
  let name = "Name";
  if (module == "Deals") {
    name = "Deal_Name";
  } else if (module == "Accounts") {
    name = "Account_Name";
  } else if (module == "Contacts" || module == "Leads") {
    name = "Full_Name";
  } else if (module == "Campaigns") {
    name = "Campaign_Name";
  }
  return "${" + name + "}";
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "background.paper",
  borderRadius: "5px",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CreateModalNode({
  currentNodeInfo,
  createModalOpen,
  handleCreateModalClose,
  addNodeUnderParent,
  getNodeKey,
  moduleFields,
  leadFields,
  savedData,
  myTreeData,
  moduleName,
}) {
  return (
    <Modal
      open={createModalOpen}
      onClose={handleCreateModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <EditNodeForm
          moduleName={moduleName}
          title={getTitle(moduleName)}
          information={{}}
          moduleFields={moduleFields}
          leadFields={leadFields}
          myTreeData={currentNodeInfo ? myTreeData : []}
          handleSubmitForm={(data) => {
            const myData = addNodeUnderParent({
              treeData: currentNodeInfo ? myTreeData : [],
              parentKey: currentNodeInfo
                ? currentNodeInfo?.path[currentNodeInfo?.path?.length - 1]
                : null,
              expandParent: true,
              getNodeKey,
              newNode: {
                information: data.information,
                title: data?.title,
              },
            });
            // ;
            // setTreeData(myData?.treeData);

            console.log({ myData });
            savedData({
              key: "treeData",
              response: myData?.treeData,
            });

            // console.log({myData});
            handleCreateModalClose();
          }}
        />
      </Box>
    </Modal>
  );
}

export default CreateModalNode;
