import { Box } from "@mui/material";
import CustomActionComponents from "../Components/CustomActionComponents/CustomActionComponents";

export default function CustomAction() {
  return (
    <Box sx={{ px: "2rem" }}>
      <CustomActionComponents />
    </Box>
  );
}
