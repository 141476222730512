import React from "react";
import { useForm, Controller } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import {
  getFilteredOptions,
  getFilteredShareUrlOptions,
  getInitialFilteredOptions,
} from "./OtherFunctions";
import { Typography } from "@mui/material";
import DynamicHashTextField from "../DynamicHashTextField/DynamicHashTextField";

const EditNodeForm = ({
  myTreeData,
  title,
  information,
  handleSubmitForm,
  moduleName,
  moduleFields,
  leadFields,
}) => {
  const {
    register,
    getValues,
    setValue,
    resetField,
    control,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      title: title,
      information: { ...information },
    },
  });
  const onSubmit = (data) => {
    console.log({ data });
    handleSubmitForm(data);
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      //  style={{ backgroundColor: "red" }}
    >
      <Box
        sx={{
          mb: ".5rem",
          "& .MuiFormControl-root": {
            width: "100% !important",
          },
        }}
      >
        <Typography>Folder Name</Typography>
        <Controller
          name="title"
          control={control}
          rules={{
            required: "The Field is required",
          }}
          render={({ field, fieldState, formState }) => (
            <DynamicHashTextField
              module={moduleName}
              fields={moduleFields}
              initialValue={title}
              savedData={(data) => {
                console.log({ moduleName, data });
                field.onChange(data);
              }}
            />
          )}
        />
      </Box>

      <Box sx={{ mb: ".5rem" }}>
        <Controller
          name="information.saveFolder.isChecked"
          control={control}
          render={({ field, fieldState, formState }) => (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value}
                    {...field}
                    disableRipple
                    size="small"
                    disabled={watch("information.moveFolderIfExist.isChecked")}
                    onChange={(event) => {
                      // if (!event?.target?.checked) {
                      //   setValue('information.saveFolder.value', null);
                      // }
                      // setValue(`information.saveFolder.value`, null);
                      // reset({
                      //   firstName: "bill",
                      //   lastName: "luo",
                      // })
                      // resetField("information.saveFolder.value")
                      setValue("information.saveFolder.value", null);
                      field.onChange(event?.target?.checked);
                    }}
                  />
                }
                label="Save Folder?"
              />
            </>
          )}
        />

        <Controller
          name="information.saveFolder.value"
          control={control}
          render={({ field, fieldState, formState }) => (
            <>
              {fieldState.defaultValue}
              <Autocomplete
                autoHighlight={true}
                size="small"
                options={getFilteredOptions(
                  getInitialFilteredOptions(moduleFields, myTreeData[0], [], 0),
                  watch("information")
                )}
                defaultValue={information?.saveFolder?.value}
                groupBy={(option) => option?.lookupDisplayLabel}
                getOptionLabel={(option) => option?.fieldDisplayLabel}
                disableClearable={true}
                onChange={(e, value) => {
                  field.onChange(value);
                }}
                disabled={!watch("information.saveFolder.isChecked")}
                value={
                  watch("information.saveFolder.isChecked")
                    ? field?.value
                    : null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Fields"
                    required={watch("information.saveFolder.isChecked")}
                  />
                )}
              />
            </>
          )}
        />
      </Box>

      {["Contacts", "Accounts", "Deals"]?.includes(moduleName) && (
        <Box sx={{ mb: ".5rem" }}>
          <Controller
            name="information.copyLeadFolder.isChecked"
            control={control}
            render={({ field, fieldState, formState }) => (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value}
                      {...field}
                      disableRipple
                      size="small"
                      onChange={(event) => {
                        // if (!event?.target?.checked) {
                        //   setValue('information.saveFolder.value', null);
                        // }
                        // setValue(`information.saveFolder.value`, null);
                        // reset({
                        //   firstName: "bill",
                        //   lastName: "luo",
                        // })
                        // resetField("information.saveFolder.value")
                        setValue("information.copyLeadFolder.value", null);
                        field.onChange(event?.target?.checked);
                      }}
                    />
                  }
                  label="Copy Lead Folder?"
                />
              </>
            )}
          />

          <Controller
            name="information.copyLeadFolder.value"
            control={control}
            render={({ field, fieldState, formState }) => (
              <>
                {fieldState.defaultValue}
                <Autocomplete
                  autoHighlight={true}
                  size="small"
                  options={leadFields}
                  defaultValue={information?.copyLeadFolder?.value}
                  groupBy={(option) => option?.lookupDisplayLabel}
                  getOptionLabel={(option) => option?.fieldDisplayLabel}
                  disableClearable={true}
                  onChange={(e, value) => {
                    field.onChange(value);
                  }}
                  disabled={!watch("information.copyLeadFolder.isChecked")}
                  value={
                    watch("information.copyLeadFolder.isChecked")
                      ? field?.value
                      : null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Fields"
                      required={watch("information.copyLeadFolder.isChecked")}
                    />
                  )}
                />
              </>
            )}
          />
        </Box>
      )}

      <Box sx={{ mb: ".5rem" }}>
        <Controller
          name="information.shareUrl.isChecked"
          control={control}
          render={({ field, fieldState, formState }) => (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    size="small"
                    disableRipple
                    onChange={(event) => {
                      // if (!event?.target?.checked) {
                      //   setValue('information.shareUrl.value', null);
                      // }
                      setValue("information.shareUrl.value", null);
                      field.onChange(event?.target?.checked);
                    }}
                  />
                }
                label="Save Share Url (Download Only)?"
              />
            </>
          )}
        />

        <Controller
          name="information.shareUrl.value"
          control={control}
          render={({ field, fieldState, formState }) => (
            <Autocomplete
              autoHighlight={true}
              size="small"
              options={getFilteredShareUrlOptions(
                getInitialFilteredOptions(moduleFields, myTreeData[0], [], 0),
                watch("information")
              )}
              defaultValue={information?.shareUrl?.value}
              groupBy={(option) => option?.lookupDisplayLabel}
              getOptionLabel={(option) => option?.fieldDisplayLabel}
              disabled={!watch("information.shareUrl.isChecked")}
              disableClearable={true}
              onChange={(e, value) => {
                field.onChange(value);
              }}
              value={
                watch("information.shareUrl.isChecked") ? field?.value : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Fields"
                  required={watch("information.shareUrl.isChecked")}
                  disabled={!watch("information.shareUrl.isChecked")}
                />
              )}
            />
          )}
        />
      </Box>

      <Box sx={{ mb: ".8rem" }}>
        <Controller
          name="information.fullAccessShareUrl.isChecked"
          control={control}
          render={({ field, fieldState, formState }) => (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    disableRipple
                    {...field}
                    checked={field.value}
                    onChange={(event) => {
                      // if (!event?.target?.checked) {
                      //   setValue('information.fullAccessShareUrl.value', null);
                      // }
                      setValue("information.fullAccessShareUrl.value", null);
                      field.onChange(event?.target?.checked);
                    }}
                  />
                }
                label="Save Share Url (Full Access)?"
              />
            </>
          )}
        />
        <Controller
          name="information.fullAccessShareUrl.value"
          control={control}
          render={({ field, fieldState, formState }) => (
            <Autocomplete
              autoHighlight={true}
              size="small"
              options={getFilteredShareUrlOptions(
                getInitialFilteredOptions(moduleFields, myTreeData[0], [], 0),
                watch("information")
              )}
              defaultValue={information?.fullAccessShareUrl?.value}
              groupBy={(option) => option?.lookupDisplayLabel}
              getOptionLabel={(option) => option?.fieldDisplayLabel}
              disableClearable={true}
              onChange={(e, value) => {
                field.onChange(value);
              }}
              disabled={!watch("information.fullAccessShareUrl.isChecked")}
              value={field?.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Fields"
                  required={watch("information.fullAccessShareUrl.isChecked")}
                />
              )}
            />
          )}
        />
      </Box>

      <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>
        Additional Information
      </Typography>

      <Box>
        <Controller
          name="information.usePreviousFolder.isChecked"
          control={control}
          render={({ field, fieldState, formState }) => (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    disableRipple
                    {...field}
                    checked={field.value}
                  />
                }
                label="Use Previous Folder?"
              />
            </>
          )}
        />
      </Box>

      <Box>
        <Controller
          name="information.moveFolderIfExist.isChecked"
          control={control}
          render={({ field, fieldState, formState }) => (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    disableRipple
                    {...field}
                    checked={field.value}
                    onChange={(event) => {
                      console.log({ fieldState, formState });
                      if (event?.target?.checked) {
                        setValue("information.saveFolder.isChecked", true);
                      }
                      // setValue(`information.saveFolder.value`, null);
                      // reset({
                      //   firstName: "bill",
                      //   lastName: "luo",
                      // })
                      // resetField("information.saveFolder.value")
                      field.onChange(event?.target?.checked);
                    }}
                  />
                }
                label="Move Folder If Exist"
              />
            </>
          )}
        />
      </Box>

      <Box sx={{ display: "flex", gap: "1rem", justifyContent: "end" }}>
        {/* <Button type="button" variant="outlined" size="small" disableRipple>
          Cancel
        </Button> */}
        <Button type="submit" variant="contained" size="small" disableRipple>
          Save
        </Button>
      </Box>
    </form>
  );
};

export default EditNodeForm;
