import React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import * as ApiCall from "../../API/ApiCalling";
import Loader from "../../Overview/Loader";
import ModuleFolderStructure from "../../Others/ModuleFolderStructure/ModuleFolderStructure";
import { Button } from "@mui/material";

function ModuleHistoricData(props) {
  const {
    children,
    index,
    module,
    orgid,
    connname,
    apikey,
    zapikey,
    datacenterurl,
    zuid,
    settingSchema,
    setSettingSchema,
    ...other
  } = props;

  const [settingId, setSettingId] = useState();

  async function getSettingData() {
    try {
      const settingDetails = await ApiCall.getSettingData(
        module,
        "systemDefined",
        connname,
        orgid,
        apikey
      );

      if (settingDetails?.error) {
        setSettingId((prev) => "demo");
        setSettingSchema((prev) => null);
        return;
      }

      console.log({ settingDetails: settingDetails?.data?.[0] });
      const { setting_id, setting_schema, setting_type, show_in_related_list } =
        settingDetails?.data?.[0];
      setSettingSchema((prev) => JSON.parse(setting_schema));
      setSettingId((prev) => setting_id);
    } catch (error) {}
  }

  useEffect(() => {
    if (orgid && connname && apikey && module) {
      getSettingData();
    }
  }, [module]);

  if (!settingId) {
    return <Loader />;
  }

  return (
    <Box
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <ModuleFolderStructure
        orgid={orgid}
        connname={connname}
        apikey={apikey}
        datacenterurl={datacenterurl}
        zuid={zuid}
        settingData={settingSchema || {}}
        setSettingData={setSettingSchema}
        module={module}
      />
    </Box>
  );
}

export default ModuleHistoricData;
