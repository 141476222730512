import axios from "axios";
const base_url = process.env.REACT_APP_API_SERVER_URL;
const setting_url = `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`;

const headers = {
  Accept: "*/*",
  "Content-Type": "application/json",
};

export const getCronJobs = async (data) => {
  const { orgid, apikey } = data;

  try {
    const base_url = process.env.REACT_APP_ADMIN_SERVER_URL;
    const crons = await axios({
      url: base_url + "/utils/cronjobs",
      method: "get",
      headers: {
        ...headers,
        orgid: orgid,
        connname: "easyworkdriveforcrm",
        apikey: apikey,
      },
    });
    // console.log({ crons: crons });
    return crons?.data;
  } catch (error) {
    // console.log({ error: error });
    return error;
  }
};

export const createCronJob = async (data) => {
  const { reqUrl, reqParams, reqHeaders, reqBody, reqType } = data;
  const { orgid, connname, apikey, datacenterurl } = reqHeaders;
  let params = {};

  try {
    const base_url = process.env.REACT_APP_ADMIN_SERVER_URL;
    const createCron = await axios({
      url: base_url + "/utils/cronjobs",
      method: "post",
      headers: {
        ...headers,
        orgid: orgid,
        connname: connname,
        apikey: apikey,
        datacenterurl: datacenterurl,
      },
      data: {
        reqUrl: reqUrl,
        reqParams: reqParams,
        reqHeaders: reqHeaders,
        reqBody: reqBody,
        reqType: reqType,
      },
    });
    console.log({ createCron: createCron?.data });
    return createCron?.data;
  } catch (error) {
    console.log({ error: error });
    return error;
  }
};

export const updateCronJob = async (data) => {
  const {
    orgid,
    apikey,
    settingId,
    settingStatus = "Stopped",
  } = data;

  try {
    const base_url = process.env.REACT_APP_ADMIN_SERVER_URL;
    const cronUpdateConfig = {
      url: `${base_url}/utils/cronjobs`,
      method: "PUT",
      headers: {
        orgid: orgid, // Org ID
        apikey: apikey, // API KEy
        connname: "easyworkdriveforcrm", // Conn Name
      },
      data: {
        settingId: settingId,
        settingStatus: settingStatus,
      },
    };

    const updateCron = await axios(cronUpdateConfig);
    return updateCron?.data;
  } catch (updateCronJob) {
    console.log({ updateCronJob: updateCronJob });
    return updateCronJob;
  }
};

export const deleteCronJob = async (data) => {
  const { orgid, apikey, settingId } = data;

  try {
    const base_url = process.env.REACT_APP_ADMIN_SERVER_URL;
    const cronDeleteConfig = {
      url: `${base_url}/utils/cronjobs/${settingId}`,
      method: "DELETE",
      headers: {
        orgid: orgid, // Org ID
        apikey: apikey, // API KEy
        connname: "easyworkdriveforcrm", // Conn Name
      },
    };

    const deleteCron = await axios(cronDeleteConfig);
    return deleteCron?.data;
  } catch (deleteCronJob) {
    console.log({ deleteCronJob: deleteCronJob });
    return deleteCronJob;
  }
};

export const getCustomViewData = async (data) => {
  const {
    moduleApiName,
    cvid,
    connname,
    orgid,
    apikey,
    datacenterurl,
    settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL,
  } = data;

  // You can pass the data in body/parsms
  let reqOptions = {
    url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/data/zoho/crm/modules/${moduleApiName}/cvid/${cvid}`,
    method: "GET",
    headers: {
      ...headers,
      connname: "easyworkdriveforcrm__zoho",
      orgid,
      apikey,
      Datacenterurl: datacenterurl,
    },
  };

  try {
    const settingResp = await axios(reqOptions);
    return settingResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};

// TODO connname, orgid, apikey should get from CRM variable
export const getTeamOfUser = async (
  zuid,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  let reqOptions = {
    url: `${base_url}/${zuid}/team`,
    method: "GET",
    headers: {
      ...headers,
      connname,
      orgid,
      apikey,
      datacenterurl,
    },
  };

  try {
    const folderResp = await axios(reqOptions);

    return folderResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};
// TODO connname, orgid, apikey should get from CRM variable

export const getTeamsFolder = async (
  zuid,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  // http://localhost:3041/v2/zohoworkdrive/2wbrkba0d1d851c3f489692419b59db7c6180/files/team
  let reqOptions = {
    url: `${base_url}/${zuid}/files/team`,
    method: "GET",
    headers: {
      ...headers,
      connname,
      orgid,
      apikey,
      datacenterurl,
    },
  };

  try {
    const folderResp = await axios(reqOptions);

    return folderResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};
// TODO connname, orgid, apikey should get from CRM variable

export const getSettingData = async (
  moduleApiName = "",
  settingType = "",
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL,
  settingId,
) => {
  let params = {};

  if (moduleApiName != "") {
    params["moduleApiName"] = moduleApiName;
  }

  if (settingType != "") {
    params["settingType"] = settingType;
  }

  let settingApiUrl = settingId? `${setting_url}/${settingId}`: `${setting_url}`;

  let reqOptions = {
    url: settingApiUrl,
    method: "GET",
    headers: {
      ...headers,
      connname,
      orgid,
      apikey,
      datacenterurl,
    },
    params: params,
  };

  try {
    const settingResp = await axios(reqOptions);

    return settingResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};
// TODO connname, orgid, apikey should get from CRM variable

export const upsertData = async (
  data = {},
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  let reqOptions = {
    url: `${setting_url}`,
    method: "POST",
    headers: {
      ...headers,
      connname,
      orgid,
      apikey,
      datacenterurl,
    },
    data: data,
  };

  try {
    const folderResp = await axios(reqOptions);
    // ;
    return folderResp?.data;
  } catch (error) {
    // ;
    return { error: error?.response?.statusText };
  }
};
// upsertData
// TODO connname, orgid, apikey should get from CRM variable

export const getFoldersData = async (
  folder_id,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  let reqOptions = {
    url: `${base_url}/${folder_id}`,
    method: "GET",
    headers: {
      ...headers,
      connname,
      orgid,
      apikey,
      datacenterurl,
    },
  };

  // ;
  try {
    const folderResp = await axios(reqOptions);
    // ;
    return folderResp?.data;
  } catch (error) {
    return { error: error?.response?.statusText };
  }
};

export const getFoldersItem = async (
  folder_id,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  let reqOptions = {
    url: `${base_url}/${folder_id}/files`,
    method: "GET",
    headers: {
      ...headers,
      connname,
      orgid,
      apikey,
      datacenterurl,
    },
    params: {
      sort: "name",
    },
  };

  // ;
  try {
    const folderResp = await axios(reqOptions);
    // ;
    return folderResp?.data;
  } catch (error) {
    // ;
    return { error: error?.response?.statusText };
  }
};

export const shareFileFolder = (
  data,
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${file.id}/share`;

  let headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
    orgid: orgid,
    connname: connname,
    apikey: apikey,
    datacenterurl,
  };

  let reqOptions = {
    url: URL,
    method: "POST",
    headers: headersList,
    data: data,
  };

  return axios(reqOptions)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export const shareFullAccess = (
  data,
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${file.id}/share/fullaccess`;

  let headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
    orgid: orgid,
    connname: connname,
    apikey: apikey,
    datacenterurl,
  };

  let reqOptions = {
    url: URL,
    method: "POST",
    headers: headersList,
    data: data,
  };

  return axios(reqOptions)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export const createFolder = (
  folder_id,
  data,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${folder_id}`;

  let reqOptions = {
    url: URL,
    method: "POST",
    headers: {
      ...headers,
      connname,
      orgid,
      apikey,
      datacenterurl,
    },
    data: data,
  };

  // ;
  return axios(reqOptions)
    .then((response) => {
      // ;
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const fileUploader = (
  data,
  parentId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${parentId}`;

  return axios
    .post(URL, data, {
      headers: {
        connname: connname,
        apikey: apikey,
        datacenterurl,
        orgid: orgid,
        datacenterurl,
        Accept: "application/vnd.api+json",
      },
    })
    .then((response) => response?.data)
    .catch((error) => {
      // // ;
      throw error;
    });
};
// Working
// TODO Rename folder for dropbox issue -> may be because of changing of id
export const renameFileFolder = (
  data,
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  // const URL = `${base_url}/v1/files/${file.id}`;

  const URL = `${base_url}/${file.id}`;
  let headersList = {
    Accept: "*/*",
    "Content-Type": "application/json",
    orgid: orgid,
    connname: connname,
    apikey: apikey,
    datacenterurl,
  };
  let reqOptions = {
    url: URL,
    method: "PUT",
    headers: headersList,
    data: {
      name: file?.extn ? `${data.name}.${file?.extn}` : data.name,
      parent_id: file?.parent_id,
    },
  };
  return axios(reqOptions)
    .then((resp) => {
      // // ;
      return resp.data;
    })
    .catch((error) => {
      // // ;
      throw error;
    });
};

// Working
export const deleteFileFolder = (
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${file.id}`;

  return axios
    .delete(URL, {
      headers: {
        connname: connname,
        apikey: apikey,
        datacenterurl,
        orgid: orgid,
        datacenterurl,
        Accept: "application/vnd.api+json",
      },
    })
    .then((response) => response?.data)
    .catch((error) => {
      // // ;
      throw error;
    });
};
// Working
export const moveFile = (
  file,
  child,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${child?.id}/move`;

  return axios
    .post(
      URL,
      {
        destFolderId: file.id,
        parent_id: child.parent_id,
      },
      {
        headers: {
          connname: connname,
          apikey: apikey,
          datacenterurl,
          orgid: orgid,
          datacenterurl,
          Accept: "application/vnd.api+json",
        },
      }
    )
    .then((response) => {
      // ;
      return response?.data;
    })
    .catch((error) => {
      // // ;
      throw error;
    });
};

export const getBreadCrumbs = (
  fileId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${fileId}/breadCrumbs`;

  return axios({
    method: "get",
    url: URL,
    headers: {
      connname: connname,
      apikey: apikey,
      datacenterurl,
      orgid: orgid,
      Accept: "application/vnd.api+json",
    },
  })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const searchInsideFolder = (
  fileId,
  query,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${fileId}/search/insideFolder`;

  return axios({
    method: "post",
    url: URL,
    headers: {
      connname: connname,
      apikey: apikey,
      datacenterurl,
      orgid: orgid,
      Accept: "application/vnd.api+json",
    },
    data: {
      query: query,
    },
  })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};
// Working
export const copyFile = (
  file,
  childId,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${childId}/copy`;
  return axios
    .post(
      URL,
      {
        destFolderId: file.id,
      },
      {
        headers: {
          connname: connname,
          apikey: apikey,
          datacenterurl,
          orgid: orgid,
          datacenterurl,
          Accept: "application/vnd.api+json",
        },
      }
    )
    .then((response) => {
      // // ;
      return response?.data;
    })
    .catch((error) => {
      // // ;
      // throw error;
    });
};

export const getImageResponse = (
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${file.id}/download`;
  return axios({
    method: "get",
    headers: {
      Accept: "application/vnd.api+json",
      connname: connname,
      apikey: apikey,
      orgid: orgid,
      datacenterurl,
    },
    responseType: "blob",
    url: URL,
  })
    .then((response) => {
      // // ;
      return response;
    })
    .catch((error) => {
      // // ;
      // throw error;
    });
};

export const getThumbnailData = (
  file,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${file.id}/thumbnail`;

  return axios({
    method: "get",
    headers: {
      connname: connname,
      apikey: apikey,
      orgid: orgid,
      datacenterurl,
    },
    responseType: "blob",
    url: URL,
  })
    .then((response) => {
      var blodData = new Blob([response.data]);
      return response;
    })
    .catch((error) => {
      return;
    });
};

export const syncAttachment = (
  folder_id,
  data,
  connname,
  orgid,
  apikey,
  datacenterurl,
  settingUrl = process.env.REACT_APP_DRIVEMANAGER_SETTING_URL
) => {
  const URL = `${base_url}/${folder_id}/sync/acctachment`;

  let reqOptions = {
    url: URL,
    method: "POST",
    headers: {
      ...headers,
      connname,
      orgid,
      apikey,
      datacenterurl,
    },
    data: data,
  };

  // ;
  return axios(reqOptions)
    .then((response) => {
      // ;
      return response?.data;
    })
    .catch((error) => {
      throw error;
    });
};
