import React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import * as ApiCall from "../../API/ApiCalling";
import Loader from "../../Overview/Loader";
import ModuleFolderStructure from "../../Others/ModuleFolderStructure/ModuleFolderStructure";
import axios from "axios";
import { Button, Typography } from "@mui/material";
import { defaultModulesWithCron } from "../../Others/Tools/GenerateRandomID";
import ClipboardCopy from "../../RelatedList/srcfolder/components/WorkDrive/Modals/ClipboardCopy";

const ZOHO = window?.ZOHO;
function CreateNewAction(props) {
  const {
    index,
    module,
    orgid,
    connname,
    apikey,
    zapikey,
    datacenterurl,
    zuid,
    settingSchema,
    setSettingSchema,
    settingId,
    setSettingId,
    ...other
  } = props;
  const [loading, setLoading] = useState(true);
  //   const [settingId, setSettingId] = useState();

  async function getDefaultSettingData() {
    try {
      const settingDetails = await ApiCall.getSettingData(
        module,
        "systemDefined",
        connname,
        orgid,
        apikey
      );

      if (settingDetails?.error) {
        setSettingSchema((prev) => null);
        setLoading((prev) => false);
        return;
      }

      console.log({ settingDetails: settingDetails?.data?.[0] });
      const { setting_id, setting_schema, setting_type, show_in_related_list } =
        settingDetails?.data?.[0];
      setSettingSchema((prev) => JSON.parse(setting_schema));
      setLoading((prev) => false);
    } catch (error) {}
  }

  async function getSettingData() {
    try {
      const settingDetails = await ApiCall.getSettingData(
        null,
        null,
        connname,
        orgid,
        apikey,
        datacenterurl,
        null,
        settingId
      );

      console.log({ settingDetails: settingDetails.data });

      if (settingDetails?.error) {
        setSettingSchema((prev) => null);
        setLoading((prev) => false);
        return;
      }

      const { setting_id, setting_schema, setting_type, show_in_related_list } =
        settingDetails.data;
      const defaultSettingSchema = JSON.parse(setting_schema);
      if (!defaultSettingSchema?.newFolderStructure) {
        let saveFolder = {};
        let folderField = null;
        let shareUrl = {};
        let fullAccessShareUrl = {};

        if (defaultSettingSchema?.buttonState?.saveFolderId) {
          // todo saveFolder

          saveFolder = {
            value: {
              type: "text",
              fieldApiName: defaultSettingSchema?.fieldApiName?.api_name,
              lookupApiName: "",
              fieldDisplayLabel:
                defaultSettingSchema?.fieldApiName?.display_name,
              lookupDisplayLabel: defaultSettingSchema?.moduleApiName,
            },
            isChecked: true,
          };
        }

        if (!defaultSettingSchema?.buttonState?.isSpecificFolder) {
          folderField = {
            type: "text",
            fieldApiName: defaultSettingSchema?.rootFolderFieldName?.api_name,
            lookupApiName: defaultSettingSchema?.moduleApiName,
            fieldDisplayLabel:
              defaultSettingSchema?.rootFolderFieldName?.display_name,
            lookupDisplayLabel: defaultSettingSchema?.moduleApiName,
          };
        }
        if (defaultSettingSchema?.externalSharedUrlSave == "Yes") {
          // shareUrl
          shareUrl = {
            value: {
              type: "",
              fieldApiName:
                defaultSettingSchema?.externalSharedUrlFieldName?.api_name,
              lookupApiName: module,
              fieldDisplayLabel:
                defaultSettingSchema?.externalSharedUrlFieldName?.display_name,
              lookupDisplayLabel: module,
            },
            isChecked: true,
          };
        } else if (
          defaultSettingSchema?.externalSharedUrlSave == "YesWithUpload"
        ) {
          // fullAccessShareUrl
          fullAccessShareUrl = {
            value: {
              type: "",
              fieldApiName:
                defaultSettingSchema?.externalSharedUrlFieldName?.api_name,
              lookupApiName: module,
              fieldDisplayLabel:
                defaultSettingSchema?.externalSharedUrlFieldName?.display_name,
              lookupDisplayLabel: module,
            },
            isChecked: true,
          };
        }
        let newTreeData = [
          {
            ...defaultSettingSchema?.treeData[0],
            information: {
              saveFolder: saveFolder,
              copyLeadFolder: {},
              moveFolderIfExist: {},
              usePreviousFolder: {},
              shareUrl: shareUrl,
              fullAccessShareUrl: fullAccessShareUrl,
            },
          },
        ];
        defaultSettingSchema["treeData"] = newTreeData;
        defaultSettingSchema["folderField"] = folderField;
        defaultSettingSchema["newFolderStructure"] = true;
        console.log({ defaultSettingSchema });
      }
      setSettingSchema((prev) => defaultSettingSchema);
      setLoading((prev) => false);
    } catch (error) {
      setLoading((prev) => false);
    }
  }

  useEffect(() => {
    if (orgid && connname && apikey && module) {
      if (settingId) {
        getSettingData();
      } else {
        setLoading((prev) => false);
      }
    }
  }, [module]);

  const handleUpsert = async (event) => {
    console.log({ settingSchema });
    const settingData = {
      ...settingSchema,
      newFolderStructure: true,
      moduleApiName: module,
    };

    const insertedData = await axios.request({
      url: `${process.env.REACT_APP_ADMIN_SERVER_URL}/db/planetscale/clients/settings`,
      method: "POST",
      data: {
        settingId: settingId || "",
        settingSchema: settingData,
        showInRelatedList: false,
        moduleApiName: module,
        settingType: "workflow",
      },
      headers: {
        orgid: orgid,
        apikey: apikey,
        datacenterurl: datacenterurl,
        connname: "easyworkdriveforcrm",
      },
    });

    const newSettingId = insertedData?.data?.data?.setting_id || "";

    if (newSettingId !== "") {
      await ZOHO.CRM.ACTION.setConfig({
        settingId: newSettingId,
        orgid: orgid,
        datacenterurl: datacenterurl,
        actionType: "Create Action",
        // apikey: snap.apiKey,
        connname: "easyworkdriveforcrm",
      });
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {settingId ? (
        <Box
          sx={{
            // mt: 1.1,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "1rem",
            mb: "1rem",
          }}
        >
          <Typography sx={{ fontWeight: "medium" }}>Setting Id:</Typography>
          <Box
            sx={{
              borderRadius: "4px",
              border: "1px solid rgba(0, 0, 0, 0.23)",
              display: "flex",
            }}
          >
            <Typography sx={{ p: ".5rem" }}>{settingId}</Typography>
            <Box
              sx={{
                bgcolor: "rgba(25, 118, 210, 0.08)",
                overflow: "hidden",
                display: "grid",
                placeItems: "center",
              }}
            >
              <ClipboardCopy
                sx={{ mb: "-8px" }}
                color={"transparent"}
                copyText={settingId}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <ModuleFolderStructure
        orgid={orgid}
        connname={connname}
        apikey={apikey}
        datacenterurl={datacenterurl}
        zuid={zuid}
        settingData={settingSchema || {}}
        setSettingData={setSettingSchema}
        module={module}
      />
      <Box sx={{ my: "1rem", display: "flex", gap: "1rem" }}>
        {/* <Button variant="outlined" size="small">
          Cancel
        </Button> */}
        <Button
          variant="contained"
          size="small"
          onClick={handleUpsert}
          disabled={
            (!settingSchema?.rootFolder?.id &&
              !settingSchema?.folderField?.fieldApiName) ||
            !settingSchema?.treeData?.[0]?.title
          }
        >
          Upsert Setting
        </Button>
      </Box>
    </div>
  );
}

export default CreateNewAction;
