import React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import * as ApiCall from "./../../API/ApiCalling";
import Loader from "../../Overview/Loader";
import ModuleFolderStructure from "../../Others/ModuleFolderStructure/ModuleFolderStructure";
import { Button } from "@mui/material";

function IndividualModuleSetting(props) {
  const {
    children,
    value,
    index,
    module,
    orgid,
    connname,
    apikey,
    zapikey,
    datacenterurl,
    zuid,
    ...other
  } = props;

  const [settingId, setSettingId] = useState();
  const [settingSchema, setSettingSchema] = useState();

  async function getSettingData() {
    try {
      const settingDetails = await ApiCall.getSettingData(
        module,
        "systemDefined",
        connname,
        orgid,
        apikey
      );

      if (settingDetails?.error) {
        return;
      }

      console.log({ settingDetails: settingDetails?.data?.[0] });
      const { setting_id, setting_schema, setting_type, show_in_related_list } =
        settingDetails?.data?.[0];
      const defaultSettingSchema = JSON.parse(setting_schema);
      if (!defaultSettingSchema?.newFolderStructure) {
        let newTreeData = [
          {
            ...defaultSettingSchema?.treeData[0],
            information: {
              shareUrl: {},
              saveFolder: {
                value: {
                  type: "text",
                  fieldApiName: defaultSettingSchema?.fieldApiName?.api_name,
                  lookupApiName: "",
                  fieldDisplayLabel:
                    defaultSettingSchema?.fieldApiName?.display_name,
                  lookupDisplayLabel: defaultSettingSchema?.moduleApiName,
                },
                isChecked: true,
              },
              copyLeadFolder: {},
              moveFolderIfExist: {},
              usePreviousFolder: {},
              fullAccessShareUrl: {},
            },
          },
        ];
        defaultSettingSchema["treeData"] = newTreeData;
        defaultSettingSchema["newFolderStructure"] = true;
      }
      setSettingId((prev) => setting_id);
      setSettingSchema((prev) => defaultSettingSchema);
    } catch (error) {}
  }

  useEffect(() => {
    if (orgid && connname && apikey) {
      getSettingData();
    }
  }, [value]);

  const handleClickUpdate = async () => {
    console.log({ settingSchema });

    try {
      const reqData = {
        settingSchema: {
          ...settingSchema,
          newFolderStructure: true,
        },
        settingId: settingId,
        moduleApiName: module,
      };

      console.log({ reqData });

      const upsertDetails = await ApiCall.upsertData(
        reqData,
        connname,
        orgid,
        apikey,
        datacenterurl
      );
      console.log({ upsertDetails });
    } catch (error) {
      console.log({ error });
    }
  };

  if (!settingId) {
    return <Loader />;
  }

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <ModuleFolderStructure
        orgid={orgid}
        connname={connname}
        apikey={apikey}
        datacenterurl={datacenterurl}
        zuid={zuid}
        settingData={settingSchema || {}}
        setSettingData={setSettingSchema}
        module={module}
      />

      <Button
        onClick={handleClickUpdate}
        variant="contained"
        size="small"
        sx={{ my: 2 }}
      >
        Update Setting
      </Button>
    </Box>
  );
}

IndividualModuleSetting.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default IndividualModuleSetting;
