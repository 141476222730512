import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import EditNodeForm from "./EditNodeForm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "background.paper",
  borderRadius: "5px",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function RemoveModalNode({ modalOpen, handleModalClose, handleNodeDelete }) {
  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          sx={{ textAlign: "center", fontWeight: "medium", fontSize: 18 }}
        >
          Are you certain that you wish to delete this node?
        </Typography>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleNodeDelete}
            sx={{ mr: 2, width: 90 }}
            size="small"
            variant="contained"
          >
            Yes
          </Button>
          <Button
            onClick={handleModalClose}
            sx={{ width: 90 }}
            size="small"
            variant="outlined"
          >
            No
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default RemoveModalNode;
