import React from "react";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";

function Loader(props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Typography sx={{ fontWeight: "bold" }}>
        Please Wait While Fetching Data...{" "}
      </Typography>
      <CircularProgress sx={{ mt: 1 }} size={26} />
    </Box>
  );
}

export default Loader;
