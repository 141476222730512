import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import * as ApiCall from "../../API/ApiCalling";
import { widState } from "../../../Store/workdriveState";
import MySortableTree, {
  baseTreeData,
} from "../../Others/SortableTree/MySortableTree";
import RootFolderSelection from "../RootFolderSelection/RootFolderSelection";
import SnackAlert from "../../RelatedList/srcfolder/components/WorkDrive/Others/SnackAlert";
import DynamicHashTextField from "../DynamicModulePicklist/DynamicModulePicklist";
import { getBaseTreeData } from "../Tools/GenerateRandomID";

const ZOHO = window.ZOHO;

function ModuleFolderStructure(props) {
  const {
    zapikey,
    orgid,
    connname,
    apikey,
    datacenterurl,
    zuid,
    settingData,
    setSettingData,
    module,
    allowMultipleRoots = false,
    customView,
  } = props;

  // const [module, setModule] = useState(settingData.module || null);
  // const [workspace, setWorkspace] = useState(settingData?.workspace || null);
  // const [teamFolder, setTeamFolder] = useState(settingData?.teamFolder || null);
  // const [folderField, setFolderField] = useState(
  //   settingData?.folderField || null
  // );

  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [step, setStep] = useState(0);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Box>
      <Box
        sx={{
          borderRadius: "8px",
          p: 2,
          bgcolor: "#f8f9fa",
          outline: "1px solid rgba(0,0,0,.23)",
        }}
      >
        <Typography sx={{ pb: 1.5, fontWeight: 500, fontSize: "16px" }}>
          Root Folder Identification and Configuration
        </Typography>
        <Box sx={{ display: "flex", gap: "1rem" }}>
          <Box>
            <Typography sx={{ fontSize: "12px", mb: "2px", fontWeight: 500 }}>
              Root Folder
            </Typography>
            {!settingData?.folderField?.fieldApiName ? (
              <RootFolderSelection
                source={"ProcessHistoricData"}
                // setFolder={setFolder}
                setStep={setStep}
                settingData={settingData ?? {}}
                module={module}
                apiData={widState?.apiData}
                saveApiData={(data) => {
                  widState?.setApiData(data);
                }}
                saveData={(data) => {
                  widState.setSettingData(data);
                  if (data?.key === "rootFolder" && data?.response?.id) {
                    setSettingData((prev) => {
                      return {
                        ...prev,
                        rootFolder: data?.response,
                      };
                    });
                  }
                  if (data?.key === "workspace" && data?.response?.id) {
                    setSettingData((prev) => {
                      return {
                        ...prev,
                        workspace: data?.response,
                      };
                    });
                  }
                  if (data?.key === "teamFolder" && data?.response?.id) {
                    setSettingData((prev) => {
                      return {
                        ...prev,
                        teamFolder: data?.response,
                      };
                    });
                  }
                  console.log({ saveData: data });
                }}
                folderDetails={widState?.folderDetails}
                setFolderDetails={(data) => {
                  widState?.setFolderDetails(data);
                }}
                addFolder={(data) => {
                  widState.addFolder(data);
                }}
                orgid={orgid}
                connname={connname}
                apikey={apikey}
                datacenterurl={datacenterurl}
                zuid={zuid}
                settingUrl={process.env.REACT_APP_DRIVEMANAGER_SETTING_URL}
              />
            ) : (
              <>
                Value in{" "}
                <strong>
                  {`${settingData?.folderField?.fieldDisplayLabel}`}{" "}
                </strong>
                field will be considered as root folder ID of below folder
                structure created for each record of
                {customView ? (
                  <>
                    <strong>
                      {" "}
                      {`${customView ? customView.name : `All ${module}`}`}{" "}
                    </strong>{" "}
                    custom view of
                    <strong> {`${module}`} </strong> module.
                  </>
                ) : (
                  <>
                    <strong> {`${module}`} </strong> module.{" "}
                  </>
                )}
              </>
            )}
          </Box>

          <Box
            sx={{
              "& .MuiAutocomplete-root": {
                p: "0 !important",
                bgcolor: "white !important",
                width: "240px !important",
              },
            }}
          >
            <Typography sx={{ fontSize: "12px", mb: "2px", fontWeight: 500 }}>
              Dynamic Root Folder
            </Typography>

            <Box>
              <DynamicHashTextField
                module={module}
                fields={[]}
                initialValue={settingData?.folderField || null}
                savedData={(data) => {
                  console.log({ data });
                  setSettingData((prev) => {
                    return {
                      ...prev,
                      folderField: data,
                    };
                  });
                }}
              />
            </Box>
          </Box>
        </Box>
        <Typography sx={{ py: 1.5, fontSize: "12px" }}>
          This section configures the root folder for modules, using the
          <Box
            as="span"
            sx={{
              bgcolor: "#EEEEEE",
              py: "2px",
              borderRadius: "4px",
              fontWeight: 500,
            }}
          >
            {" "}
            (e.g., Created By User Workdrive Team){" "}
          </Box>
          to organize folders for each record in the
          <Box
            as="span"
            sx={{
              bgcolor: "#EEEEEE",
              py: "2px",
              borderRadius: "4px",
              fontWeight: 500,
            }}
          >
            {" "}
            Leads{" "}
          </Box>
          module.
        </Typography>
        <Box
          sx={{
            bgcolor: "#fff",
            borderRadius: "8px",
            outline: "1px solid rgba(0,0,0,.23)",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              pt: 2,
              px: 2,
            }}
          >
            Folder Structure{" "}
          </Typography>
          <MySortableTree
            allowMultipleRoots={allowMultipleRoots}
            moduleName={module ?? ""}
            myTreeData={settingData?.treeData || getBaseTreeData(module)}
            savedData={(data) => {
              setSettingData((prev) => {
                return {
                  ...prev,
                  treeData: data?.response,
                };
              });
            }}
            previousConfig={true}
          />
        </Box>
      </Box>

      <SnackAlert
        duration={7000}
        snackOpen={open}
        handleCloseSnack={handleCloseSnackbar}
        severity={severity}
        message={message}
      />
    </Box>
  );
}

export default ModuleFolderStructure;
